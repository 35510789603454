import react from 'react';


const Networks = () => {
    return (
        <div className="bg-pageBackground p-4 md:p-8 lg:p-16 rounded shadow-lg fade-in mx-auto max-w-5xl">
            <h1 className="text-4xl text-center font-bold mb-10">Simple Sites</h1>
            

                <h2 className="text-2xl md:text-3xl mt-6 mb-2 text-center">Introduction</h2>
                <p>
                This section offers a broad overview of diverse network topologies, detailing the devices involved and their application across various cost and utility spectrums. While it provides a foundational guide, actual deployments may vary to suit specific real-world needs. From compact SOHO networks to expansive setups for businesses with 30+ employees, we explore the adaptable nature of network design.
                </p>


            <a href="https://qr-generator-52827.firebaseapp.com/" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    SOHO Star Topology
                </h1>
            </a>
            <div className="mb-4">
                <a href="https://qr-generator-52827.firebaseapp.com/">
                    {/* <img src={qrsite} 
                        alt="home page" 
                        className="block mx-auto md:float-left md:w-84 md:h-48 mr-4 mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                    /> */}
                </a>
                <p className="text-justify max-w-prose mx-auto text-xl">
                This Figma project presents a visual guide to setting up Small Office/Home Office (SOHO) networks, showcasing three distinct configurations tailored for varying needs and budgets. From the minimalist "Bare Bones" setup, ideal for those requiring only the essentials, to the "Equipped" and "Fully Equipped" versions, which introduce progressively advanced features for enhanced functionality and security, this project offers a clear, accessible overview for anyone looking to understand or implement SOHO networking solutions. Whether you're aiming to keep costs low or seeking a comprehensive office network, this guide provides valuable insights into the components and design considerations of effective SOHO networks. 
                </p>
            </div>

            <div className="mt-2">
                <div className="flex justify-center items-center">
                    <a href="https://www.figma.com/file/UTkdVbmTkZqovMMiM0BVvH/Networking?type=design&node-id=0%3A1&mode=design&t=wxSBa9lZxu0SZEow-1" className="bg-buttonColor text-white px-4 py-2 rounded shadow hover:bg-buttonHover transition transform duration-300 hover:scale-105">
                        SOHO Figma Project
                    </a>
                </div>
            </div>

            <br />
            <br />
            <br />
        </div>
    );
}
export default Networks;
import React from 'react';
import MTGEcommerceCart from '../../assets/images/UIUXProjects/MTGEcommerceUI/MTGEcommerce-Cart page.png'
import MTGEcommerceLanding from '../../assets/images/UIUXProjects/MTGEcommerceUI/MTGEcommerce-Landing page.png'
import MTGEcommerceProduct from "../../assets/images/UIUXProjects/MTGEcommerceUI/MTGEcommerce-Product Page (1).png"
import MTGEcommerceHifiOne from "../../assets/images/UIUXProjects/MTGEcommerceUI/MTGEcommerce-hifi-1.png"
import MTGEcommerceHifiTwo from "../../assets/images/UIUXProjects/MTGEcommerceUI/MTGEcommerce-hifi-2.png"
import MTGEcommerceHifiThree from "../../assets/images/UIUXProjects/MTGEcommerceUI/MTGEcommerce-hifi-3.png"

const MTGEcommerceUI = () => {
    return (
        <div className="bg-pageBackground p-8 md:p-16 rounded shadow-lg fade-in max-w-5xl mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-center">MTG Ecommerce App</h2>

            {/* 2. Designer's Role */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Designer's Role</h3>
                <p className="text-sm md:text-base">
                    Comprehensively designed and developed, this project encapsulates front-end, back-end, and UX/UI disciplines, exemplifying a holistic full-stack approach.
                </p>
            </section>

            {/* Project Objectives */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Project Objectives</h3>
                <p className="text-sm md:text-base">
                    The main objective was to architect a sophisticated yet user-friendly online platform for MTG cards. A vital facet was bestowing the website's administrators with unparalleled customizability; enabling them to tailor landing page elements, modify product listings, and even personalize section titles to resonate with their branding. For the end-users, the site aimed to simplify the navigation experience, featuring robust filtering options for the cards and ensuring a seamless and secure purchase process. All of these factors converge to offer a holistic and intuitive MTG card shopping journey.
                </p>
            </section>

            {/* Target Audience */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Target Audience</h3>
                <p className="text-sm md:text-base">
                    The platform was meticulously crafted to cater to a dual audience. On one hand, business proprietors, particularly those helming card shops, would find the backend management tools tailored to their administrative needs. On the other, MTG card aficionados, spanning from novice collectors to seasoned enthusiasts, are provided with an immersive shopping environment, making the card discovery and purchase journey both exciting and efficient.
                </p>
            </section>

            {/* Key Challenges/Constraints */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Key Challenges</h3>
                <p className="text-sm md:text-base">
                    Crafting an e-commerce environment for MTG cards was a complex endeavor, faced with multifaceted challenges. At the forefront was the integration of an expansive card database, rife with variables ranging from card type, foiling, edition, to price in USD and quantity. The imperative was not merely on assimilation, but ensuring seamless filtering for these multifarious attributes. Concurrently, it was pivotal to guarantee the platform's optimal performance, even when confronted with vast amounts of card data. Another dimension of challenge was striking the delicate balance in design: furnishing business owners with a rich palette of customizable options for the landing page, while ensuring the interface remains intuitive and user-friendly for the end consumers.
                </p>
            </section>

            {/* Research Study Details */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">User Research</h3>
                <p className="text-sm md:text-base">
                    Delving into the heart of our target audience, primarily small business owners and startups, our research approach employed empathy maps to intimately understand their needs. This immersive method unveiled that these business owners harbored a keen interest in personalizing the landing page to their unique tastes and retaining full control over the product page. Such insights pave the way for businesses to maximize opportunities such as holiday sales, crafting a magnetic allure for potential customers. Additionally, surveys conducted among MTG card shop proprietors and avid players underscored a resonating sentiment: over 80% accentuated the indispensable nature of advanced filtering, catering to the intricate nuances of card attributes.
                </p>
            </section>


            {/* User research pain points section */}
            <section>
                <div className="bg-gray-100 p-4 max-w-full mx-auto">
                    <h3 className="text-2xl mb-4">User Pain Points</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">1</span>
                            <span className="font-semibold">Complex Checkout Process</span>
                            <p>The checkout experience was often convoluted, deterring potential customers. This accentuated the need for a more streamlined and intuitive purchase flow.</p>
                        </div>

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">2</span>
                            <span className="font-semibold">Absence of Personalized Landing Page</span>
                            <p>Users lamented the static, rigid landing page designs. Moving forward, a dynamic page that administrators could modify emerged as a crucial requirement.</p>
                        </div>

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">3</span>
                            <span className="font-semibold">Inadequate Card Filtering Options</span>
                            <p>With the diverse array of MTG cards, the lack of robust filtering hampered user experience. Enhancing this became a design imperative for effective card selection.</p>
                        </div>

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">4</span>
                            <span className="font-semibold">Limited Product Page Control</span>
                            <p>Administrators expressed a desire for deeper control over product listings. This highlighted the need for an easily configurable product display.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of user research pain points section */}


            {/* Initial Design Concepts */}
            <section className="mb-6">
                <h3 className="text-2xl mb-4">Initial Design Concepts</h3>
                <p>Began with a card-grid visual while infusing advanced filters as per user feedback. Card images were emphasized with hover effects for detailed views.</p>
            </section>

            {/* 8. Wireframes */}
            <section className="mb-6">
                <h3 className="text-2xl mb-2">Wireframes</h3>
                <p className="mt-2">Below are the wireframes, detailing the mobile views of the primary pages for our MTG e-commerce platform.</p>
                
                <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-24"> 
                    <div className="relative group">
                        <img 
                            className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                            src={MTGEcommerceCart} 
                            alt="Cart page wireframe" 
                        />
                        <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                            Cart View: Designed for clarity and ease, displaying items and their quantities. Features for modifying quantities or removing items are accessible, alongside a cart summary showing tax, discounts, and the total amount.
                        </div>
                    </div>
                    
                    <div className="relative group">
                        <img 
                            className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                            src={MTGEcommerceLanding} 
                            alt="Landing page wireframe" 
                        />
                        <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                            Landing Page: Showcases carouselled items and their distinct names, offering admins the flexibility to easily customize both content and titles.
                        </div>
                    </div>
                    
                    <div className="relative group">
                        <img 
                            className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                            src={MTGEcommerceProduct} 
                            alt="Product page wireframe" 
                        />
                        <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                            Product Page: Highlights the product with vivid imagery, additional views, and a comprehensive description detailing price, quantity, color, size, card type, and rarity.
                        </div>
                    </div>
                </div> 
            </section>


            {/* User Testing Results */}
            <section className="mb-6">
                <h3 className="text-2xl mb-4">Insights from User Testing</h3>
                <p>Upon engaging in comprehensive user testing, we gleaned several critical insights aligned with the identified pain points:</p>

                <ul className="mt-2 pl-6 list-disc">
                    <li>Filter Functionality: Tailored to the unique needs of our audience, our filter feature was a hit, receiving praise from 28 out of the 30 test users for its precision and ease-of-use. This affirms our design response to the previously noted pain point of inadequate card filtering options.</li>
                    
                    <li>Checkout Process: Feedback suggested that our revamped checkout process was perceived as seamless and straightforward, addressing the pain point related to complex checkouts that deterred customers previously.</li>
                    
                    <li>Dynamic Landing Page: A significant portion of our users appreciated the enhanced customizability of the landing page, emphasizing the value of personalization, which responded directly to the pain point concerning the absence of a personalized landing page.</li>
                    
                    <li>Product Page Control: In line with the pain point regarding limited control over product pages, user testing feedback underscored the appreciation for the increased autonomy and configurability provided to administrators for product listings.</li>
                </ul>
            </section>


            {/* 10. Mockups or High-fidelity Prototypes */}
            <section className="mb-6 text-center">
                <h3 className="text-2xl mb-2">Refined Desktop High-fidelity Prototypes</h3>
                <p className="mt-2">These are the advanced desktop prototypes shaped by invaluable user feedback and insights from our usability studies.</p>
                
                <div className="flex flex-wrap justify-center space-y-8"> 
                    <img 
                    className="w-full md:w-3/4 border-2 border-black shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                    src={MTGEcommerceHifiOne} 
                    alt="High-fidelity prototype of the Top 100 page" 
                    />
                    
                    <img 
                    className="w-full md:w-3/4 border-2 border-black shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                    src={MTGEcommerceHifiTwo} 
                    alt="High-fidelity prototype of the Landing page" 
                    />
                    
                    <img className="w-full md:w-3/4 border-2 border-black shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                    src={MTGEcommerceHifiThree} 
                    alt="High-fidelity prototype of the Statistics page" 
                    />
                </div> 
            </section>

            {/* Live Project Link */}
            <section className="mb-6">
            <h3 className="text-2xl mb-2 text-center">Visit My Site</h3>
            <div className="flex justify-center items-center">
                    <a 
                    href="https://ecommerce-cards-57ca7.firebaseapp.com/" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="bg-buttonColor hover:bg-buttonHover text-white py-3 px-6 rounded inline-block mt-4 shadow-lg transition transform duration-300 hover:scale-105"
                    >
                        View The Live Project
                    </a>
            </div>
            </section>
        </div>
    );
}

export default MTGEcommerceUI;
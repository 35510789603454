import React from 'react';
import qrsite from '../../assets/images/Project/SimpleSites/qrsite.PNG';

const SimpleSites = () => {
    return (
        <div className="bg-pageBackground p-4 md:p-8 lg:p-16 rounded shadow-lg fade-in mx-auto max-w-5xl">
            <h1 className="text-4xl text-center font-bold mb-10">Simple Sites</h1>
            

                <h2 className="text-2xl md:text-3xl mt-6 mb-2 text-center">Introduction</h2>
                <p>
                    Some very simple and light weight sites or applications that are easy to use and pretty strait forward. Feel free to head over to any of them and try them out!
                </p>


            <a href="https://qr-generator-52827.firebaseapp.com/" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    QR Code Generator
                </h1>
            </a>
            <div className="mb-4">
                <a href="https://qr-generator-52827.firebaseapp.com/">
                    <img src={qrsite} 
                        alt="home page" 
                        className="block mx-auto md:float-left md:w-84 md:h-48 mr-4 mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                    />
                </a>
                <p className="text-justify max-w-prose mx-auto text-xl">
                    This simple site was made using react for the front-end of the application and firebase as the back-end, mind you only the hosting feature of firebase. This site is extremely light weight and easy to use, just type in a link or what ever data you desire to be on the QR code and download the code as a SVG. 
                </p>
            </div>

            <div className="mt-2">
                <div className="flex justify-center items-center">
                    <a href="https://qr-generator-52827.firebaseapp.com/" className="bg-buttonColor text-white px-4 py-2 rounded shadow hover:bg-buttonHover transition transform duration-300 hover:scale-105">
                        QR Code Generator
                    </a>
                </div>
            </div>

            {/* <a href="https://card-collection-1c1cb.firebaseapp.com/statistics" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Statistics
                </h1>
            </a>

            <div className="mb-8">
                <a href="https://card-collection-1c1cb.firebaseapp.com/statistics">
                    <img src={stats} 
                        alt="Statistics Page" 
                        className="block mx-auto mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50 md:float-right md:w-84 md:h-48 md:ml-4" 
                    />
                </a>

                <p className="text-justify max-w-prose text-xl mx-auto">
                    The statistics page is my most proud page in all of webdevelopment I have created so far! It shows the complete break down of the collection as a whole in regards to price, quantity, rarity, foils cmc(converted mana cost) and others factors as well. Although the top of the page is filled with many charts representing values those who are not to interested in charts can scroll to the middle or bottom of the page to visually view the statistical breakout. I have made bar charts, pie charts and a top 5 cards by price for not only each color but also multicolor(any color that has more than one color, 2-5). Hopefully you will view my statistics page!
                </p>
            </div> */}


            <br />
            <br />
            <br />
        </div>
    );
}

export default SimpleSites;

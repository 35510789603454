import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    // State for managing active dropdown and subdropdown
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [activeSubDropdown, setActiveSubDropdown] = useState(null);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false); // State for mobile navigation toggle

    // Functions to toggle visibility of dropdowns and subdropdowns
    const toggleDropdown = (dropdownName) => {
        setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
    };
    const toggleSubDropdown = (subDropdownName) => {
        setActiveSubDropdown(activeSubDropdown === subDropdownName ? null : subDropdownName);
    };
    const closeAllDropdowns = () => {
        setActiveDropdown(null);
        setActiveSubDropdown(null);
    };

    // Close all dropdowns when click occurs outside the navbar
    useEffect(() => {
        const closeDropdownsOnClickOutside = (event) => {
            if (!event.target.closest('nav')) {
                closeAllDropdowns();
            }
        };
        document.addEventListener('click', closeDropdownsOnClickOutside);
        return () => {
            document.removeEventListener('click', closeDropdownsOnClickOutside);
        };
    }, []);


    return (
        <nav className="flex justify-center items-center p-4 bg-customNavbar text-white">
            
            {/* Navbar button */}
            <button 
            className="lg:hidden p-4" 
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            >
                <FontAwesomeIcon icon={faBars} />
            </button>
            
            <Link to="/" className="text-xl font-bold lg:text-2xl hover:text-onHover lg:ml-8 flex-1" onClick={closeAllDropdowns}>
                TimPorter
            </Link>

            {/* Desktop Navbar */}
            <div className="hidden lg:block">
                <div className="flex items-center">
                    <div className="relative mr-14">
                        <Link 
                            onClick={(e) => {
                                e.preventDefault();
                                toggleDropdown('Projects');
                            }} 
                            className="hover:text-onHover text-base lg:text-lg xl:text-xl cursor-pointer"
                        >
                            Projects
                        </Link>
                        <div style={{ display: activeDropdown === 'Projects' ? 'block' : 'none' }} className="absolute left-0 w30 bg-white text-black shadow-lg transition ease-out duration-200 z-10">
                            <Link to="/mtg-collection" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>MTG Collection</Link>
                            <Link to="/mtg-ecommerce" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>MTG Ecommerce</Link>
                            <div className="relative">
                                <span onClick={() => toggleSubDropdown('UI/UX')} className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">UI/UX</span>
                                <div style={{ display: activeSubDropdown === 'UI/UX' ? 'block' : 'none' }} className="absolute left-full top-0 w-40 bg-white text-black shadow-lg transition ease-out duration-200">
                                    <Link to="/food-truck" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>Bussin Food App</Link>
                                    <Link to="/mtg-collection-ui" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>MTG Collection App</Link>
                                    <Link to="/mtg-ecommerce-ui" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>MTG Ecommerce Store</Link>
                                </div>
                            </div>
                            <div className="relative">
                                <span onClick={() => toggleSubDropdown('Network+ Projects')} className="block px-4 py-2 hover:bg-gray-200 cursor-pointer">Network+ Projects</span>
                                <div style={{ display: activeSubDropdown === 'Network+ Projects' ? 'block' : 'none' }} className="absolute left-full top-0 w-40 bg-white text-black shadow-lg transition ease-out duration-200">
                                    <Link to="/networks" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>Networks</Link>
                                </div>
                            </div>
                            <Link to="/simple-sites" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>Simple Sites</Link>
                            <Link to="/future-roadmap" className="block px-4 py-2 hover:bg-gray-200" onClick={closeAllDropdowns}>Future Roadmap</Link>
                        </div>
                    </div>

                    <div>
                        <Link 
                            to="/certifications"
                            className="hover:text-onHover text-base lg:text-lg xl:text-xl cursor-pointer mr-14"
                        >
                            Certifications
                        </Link>

                        <Link 
                            to="/contact"
                            className="text-base lg:text-lg xl:text-xl cursor-pointer hover:text-onHover"
                        >
                            Contact Me
                        </Link>
                    </div>
                </div>
            </div>


            {/* Mobile Navbar */}
            {isMobileNavOpen && (
                <div className="absolute top-0 left-0 h-full bg-customNavbar text-white p-4 lg:hidden z-10">

                    <button
                    onClick={() => setIsMobileNavOpen(false)}
                    className="absolute top-4 right-4 p-2 bg-red-600 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    >
                        <FontAwesomeIcon icon={faTimes} className="text-lg" />
                    </button>

                    <Link to="/" className="block mb-4" onClick={() => setIsMobileNavOpen(false)}>Home</Link>
                    {/* new nested tree with Projects here */}
                    <Link to="/mtg-collection" className="block mb-4" onClick={() => setIsMobileNavOpen(false)}>MTG Collection</Link>
                    <Link to="/mtg-ecommerce" className="block mb-4" onClick={() => setIsMobileNavOpen(false)}>MTG Ecommerce Store</Link>

                    <div className="mb-4">
                        <span 
                            className="block cursor-pointer" 
                            onClick={() => toggleDropdown('MobileUI/UX')}
                        >
                            UI/UX
                        </span>
                        <div style={{ display: activeDropdown === 'MobileUI/UX' ? 'block' : 'none' }} className="pl-4">
                            <Link to="/food-truck" className="block mb-2" onClick={() => setIsMobileNavOpen(false)}>Bussin Food App</Link>
                            <Link to="/mtg-collection-ui" className="block mb-2" onClick={() => setIsMobileNavOpen(false)}>MTG Collection App</Link>
                            <Link to="/mtg-ecommerce-ui" className="block mb-2" onClick={() => setIsMobileNavOpen(false)}>MTG Ecommerce Store</Link>
                        </div>
                    </div>

                    <div className="mb-4">
                        <span 
                            className="block cursor-pointer" 
                            onClick={() => toggleDropdown('MobileNetwork+ Projects')}
                        >
                            Network+ Projects
                        </span>
                        <div style={{ display: activeDropdown === 'MobileNetwork+ Projects' ? 'block' : 'none' }} className="pl-4">
                            <Link to="/networks" className="block mb-2" onClick={() => setIsMobileNavOpen(false)}>Networks</Link>
                        </div>
                    </div>

                    <div className="mb-4">

                        <Link to="/certifications" className="block mb-2 cursor-pointer" onClick={() => setIsMobileNavOpen(false)}>
                            Certifications 
                        </Link>

                        <Link to="/simple-sites" className="block mb-2 cursor-pointer" onClick={() => setIsMobileNavOpen(false)}>
                            Simple Sites
                        </Link>

                        <Link to="/future-roadmap" className="block mb-2 cursor-pointer" onClick={() => setIsMobileNavOpen(false)}>
                            Future Roadmap 
                        </Link>

                        <Link to="/contact" className="block mb-2 cursor-pointer" onClick={() => setIsMobileNavOpen(false)}>
                            Contact Me
                        </Link>

                    </div>
                </div>
            )}
        </nav>
    );
}

export default Navbar;

import React from 'react';
import mtgCollectionMenuImg from '../../assets/images/UIUXProjects/MTGCollectionUI/MTGCollection-Menu.png';
import mtgCollectionManageImg from '../../assets/images/UIUXProjects/MTGCollectionUI/MTGCollection-manage.png';
import mtgCollectionTop100Img from '../../assets/images/UIUXProjects/MTGCollectionUI/MTGCollection-Top 100.png';
import mtgCollectionHifi1 from '../../assets/images/UIUXProjects/MTGCollectionUI/MTGCollection-hifi-1.png';
import mtgCollectionHifi2 from '../../assets/images/UIUXProjects/MTGCollectionUI/MTGCollection-hifi-2.png';
import mtgCollectionHifi3 from '../../assets/images/UIUXProjects/MTGCollectionUI/MTGCollection-hifi-3.png';


const MTGCollectionProject = () => {
    return (
        <div className="mtg-collection-project bg-pageBackground p-8 md:p-16 rounded shadow-lg fade-in max-w-5xl mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-center">Magic The Gathering Collection App</h2>

            {/* 2. Designer's Role */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Designer's Role</h3>
                <p className="text-sm md:text-base">Comprehensively designed and developed, this project encapsulates front-end, back-end, and UX/UI disciplines, exemplifying a holistic full-stack approach.</p>
            </section>

            {/* 3. Project Goal */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Project Objectives</h3>
                <p className="text-sm md:text-base">
                    In the realm of project objectives, our foremost ambition was the conceptualization and realization of a website, optimized for mobile devices, that elegantly displays the esteemed Magic The Gathering collection alongside its pertinent statistics. We placed a premium on the following:
                </p>
                <ul className="list-disc pl-4 mt-1 md:pl-5 md:mt-2">
                    <li><strong>Intuitive Functionality</strong>: Tailored to empower even those less acquainted with technology, the platform facilitates effortless addition and removal of items. Users have the flexibility to input data via individual card formats or upload a comprehensive CSV for bulk entries.</li>
                    <li><strong>Responsive Design</strong>: While the mobile experience was our guiding star, we ensured that the website also flourishes on desktop environments, capturing the best of both worlds.</li>
                    <li><strong>Comprehensive Search</strong>: Empowering users with the capability to meticulously query their entire collection, ensuring no card is ever out of reach.</li>
                    <li><strong>In-depth Analysis</strong>: The platform is adept at delivering specific statistical insights, making the user privy to trends and patterns irrespective of the collection's magnitude.</li>
                </ul>
            </section>

            {/* 4. Target Audience */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Target Audience</h3>
                <p className="text-sm md:text-base">While our platform is tailored to meet the intricate needs of Magic The Gathering enthusiasts boasting vast collections, it's equally adept at serving those curators with a burgeoning assortment. From the seasoned collector with an expansive repository to the budding enthusiast just starting out, our application ensures every Magic The Gathering devotee feels right at home.</p>
            </section>

            {/* 5. Key Challenges */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Key Challenges</h3>
                <p className="text-sm md:text-base">
                    {/* Content for Key Challenges */}
                </p>
            </section>

            {/* 6. Research Study Details */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">User Research</h3>
                <p className="text-sm md:text-base">
                    Our focal group for this project primarily consists of Magic The Gathering players. To better understand the unique needs and pain points of this audience, we employed empathy maps to discern which player profiles would derive the most value from our solution.
                </p>
                <p className="text-sm md:text-base mt-2">
                    Our research illuminated that players, especially those with extensive collections, harbor a strong desire to not only flaunt the statistical overview of their amassed treasures but also to have a robust digital querying capability at their fingertips. This functionality serves a dual purpose: it offers collectors the pride of showcasing their possession, while simultaneously facilitating trading discussions among peers. Having a digital overview provides both the user and their fellow enthusiasts a clear, comprehensive snapshot, streamlining trade negotiations and enhancing overall engagement within the Magic The Gathering community.
                </p>
            </section>


            {/* User research pain points section */}
            <section>
                <div className="bg-gray-100 md:p-4 max-w-full mx-auto">
                    <h3 className="text-2xl mb-4">User Pain Points</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">1</span>
                            <span className="font-semibold">Transport Challenges</span>
                            <p>Carrying an extensive collection to events or gatherings is cumbersome, often preventing players from showcasing their prized cards.</p>
                        </div>

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">2</span>
                            <span className="font-semibold">Search Delays</span>
                            <p>Players often face lengthy searches within their collection, wasting time locating specific cards or verifying their absence.</p>
                        </div>

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">3</span>
                            <span className="font-semibold">Memory Gaps</span>
                            <p>Over time, players might forget the specifics of their collection, leading to unintended duplicate purchases.</p>
                        </div>

                        <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow md:duration-300 md:ease-in-out md:hover:scale-125">
                            <span className="text-3xl font-bold">4</span>
                            <span className="font-semibold">Digital Advantage</span>
                            <p>A digital representation of a collection streamlines card comparisons, clarifying what players possess and what they're seeking.</p>
                        </div>

                    </div>
                </div>
            </section>
            {/* End of user research pain points section */}


        {/* 7. Initial Design Concepts */}
        <section className="mb-6">
            <h3 className="text-2xl mb-2">Initial Design Concepts</h3>
            <p>
                Recognizing that most users would predominantly access the platform through their mobile devices, a mobile-first design approach was adopted. Brainstorming sessions with pen and paper culminated in wireframes that championed simplicity and intuitive navigation. The emphasis was not only on aesthetics but also on ensuring a seamless experience for users, especially when uploading and managing their expansive collections.
            </p>
        </section>

        {/* 8. Wireframes */}
        <section className="mb-6">
            <h3 className="text-2xl mb-2">Wireframes</h3>
            <p className="mt-2">Detailed wireframes crafted in Figma provide insights into the initial design based on comprehensive user research, honing in on the needs and desires of our target audience.</p>
            
            <div className="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-6"> 
                <div className="relative group">
                    <img 
                        className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                        src={mtgCollectionMenuImg} 
                        alt="Landing page wireframe" 
                    />
                    <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                        Landing Page: Showcases the user's collection at a glance with an open navigation and a dedicated search bar for quick queries.
                    </div>
                </div>
                
                <div className="relative group">
                    <img 
                        className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                        src={mtgCollectionManageImg} 
                        alt="Manage page wireframe" 
                    />
                    <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                        Manage Page: Specifically for the collection owner, this design emphasizes the effortless process of adding or removing cards.
                    </div>
                </div>
                
                <div className="relative group">
                    <img 
                        className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                        src={mtgCollectionTop100Img} 
                        alt="Top 100 cards wireframe" 
                    />
                    <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                        Top 100 Page: Displays the crème de la crème of the user's collection, highlighting card values like name, edition, quantity, and price.
                    </div>
                </div>
            </div> 
        </section>




            {/* 9. User Testing Results */}
            <section className="mb-6">
                <h3 className="text-2xl mb-2">Insights from User Testing</h3>
                <p>
                    We undertook two comprehensive usability studies. The invaluable feedback from the initial study shaped our journey from initial wireframes to refined mockups. Prominent insights highlighted users' preferences for:
                    <ul className="list-disc list-inside mt-2">
                        <li>Swift access to their collections,</li>
                        <li>Convenient bulk update options,</li>
                        <li>Efficient card search functionality,</li>
                        <li>Viewing top cards at a glance, and</li>
                        <li>Capability to zoom in on card images for details.</li>
                    </ul>
                </p>
            </section>

            {/* 10. Mockups or High-fidelity Prototypes */}
            <section className="mb-6 text-center">
                <h3 className="text-2xl mb-2">Refined Desktop High-fidelity Prototypes</h3>
                <p className="mt-2">These are the advanced desktop prototypes shaped by invaluable user feedback and insights from our usability studies.</p>
                
                <div className="flex flex-wrap justify-center space-y-8"> 
                    <img 
                    className="w-full md:w-3/4 border-2 border-black shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                    src={mtgCollectionHifi1} 
                    alt="High-fidelity prototype of the Top 100 page" 
                    />
                    
                    <img 
                    className="w-full md:w-3/4 border-2 border-black shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                    src={mtgCollectionHifi2} 
                    alt="High-fidelity prototype of the Landing page" 
                    />
                    
                    <img className="w-full md:w-3/4 border-2 border-black shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                    src={mtgCollectionHifi3} 
                    alt="High-fidelity prototype of the Statistics page" 
                    />
                </div> 
            </section>


            {/* 11. Call-to-Action */}
            <section className="mb-6">
                <h3 className="text-2xl mb-2 text-center">Visit My Site</h3>
                <div className="flex justify-center items-center">
                    <a 
                    href="https://card-collection-1c1cb.firebaseapp.com/" 
                    target="_blank" rel="noopener noreferrer" 
                    className="bg-buttonColor hover:bg-buttonHover text-white py-3 px-6 rounded inline-block mt-4 shadow-lg transition transform duration-300 hover:scale-105"
                    >
                        View The Live Project
                    </a>
                </div>

            </section>
        </div>
    );
}

export default MTGCollectionProject;

import React from 'react';
import { Link } from 'react-router-dom';
import cart from '../../assets/images/Project/mtgecommerce/cart.png';
import filter from '../../assets/images/Project/mtgecommerce/filter.png';
import item from '../../assets/images/Project/mtgecommerce/item.png';
import landing from '../../assets/images/Project/mtgecommerce/landing.png';
import login from '../../assets/images/Project/mtgecommerce/login.png';
import products from '../../assets/images/Project/mtgecommerce/products.png';




const MTGEcommerce = () => {
    return (
        <div className="bg-pageBackground p-8 lg:p-16 rounded shadow-lg fade-in mx-auto max-w-5xl">
            <h1 className="text-4xl font-bold text-center mb-4">MTG Ecommerce Website</h1>
            <p className="text-lg">
                The MTG Ecommerce project is a specialized platform designed to cater to the needs of Magic: The Gathering enthusiasts. It offers a seamless shopping experience for MTG cards, enabling users to browse, select, and purchase their favorite cards with ease.
            </p>

            <h2 className="text-xl font-semibold">Technologies Used</h2>
            <ul className="list-disc list-inside pl-5 space-y-2">
                <li><strong>Frontend:</strong> Developed using JavaScript, React and Node.js to ensure a responsive and dynamic user interface.</li>
                <li><strong>Firebase:</strong>
                    <ul className="list-disc list-inside pl-5">
                        <li><strong>Firestore Database:</strong> Used to store card data, user preferences, and transaction history.</li>
                        <li><strong>Firebase Hosting:</strong> Provides a scalable and secure hosting solution for the application.</li>
                        <li><strong>Firebase Authentication:</strong> Enables user registration, login, and secure data access.</li>
                    </ul>
                </li>
            </ul>

            <h2 className="text-xl font-semibold">Features</h2>
            <ul className="list-disc list-inside pl-5 space-y-2">
                <li>Advanced card search and filtering.</li>
                <li>Secure payment gateway integration.</li>
                <li>User-friendly card management dashboard for sellers.</li>
                <li>Responsive design optimized for various screen sizes and devices.</li>
            </ul>


            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Landing Page
                </h1>
            </a>

            <div className="mb-8">
            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/">
                <img src={landing} 
                    alt="landing page" 
                    className="block mx-auto md:float-left md:w-84 md:h-48 mr-4 mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                />
            </a>

                <p className="text-justify max-w-prose text-xl mx-auto">
                    For the landing page I deployed cevral caracels for users to scroll through. These caracels are curated by the site's administrator/owner. They can be renamed, removed and the items within can be specifically chosen for the caracel. That is why if you notice the picture there are themes and the items in each caracel match the them. Also notice the red price tag indicating an item that is currently on sale.
                </p>
            </div>

            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/products" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Products
                </h1>
            </a>
            <div className="mb-8">
            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/products">
                <img src={products} 
                    alt="products page" 
                    className="block mx-auto mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50 md:float-right md:w-84 md:h-48 md:ml-4" 
                />
            </a>

                <p className="text-justify max-w-prose text-xl mx-auto">
                    For the products page the user can scroll through all the items that are currently posted within the site. Each item has a slight on hover effect to help the user read some of the fine print if they are visually impaired. I tried to keep the items descriptions short and sweet to prevent to much overload on the user hence why only the image of the item, quantity,foiling and price is displayed. When transitioning to diffrent screen sizes the products page collapses rather nicely, give it a try!
                </p>
            </div>

            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/products" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Filter Bar
                </h1>
            </a>

            <div className="mb-8">
                <a href="https://ecommerce-cards-57ca7.firebaseapp.com/products">
                    <img src={filter} 
                        alt="filter bar" 
                        className="block mx-auto md:float-left md:w-84 md:h-48 mr-4 mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                    />
                </a>
                <p className="text-justify max-w-prose text-xl mx-auto">
                The filter bar will obviously allow the user to filer throughout the product page where it will be filtered by color, rarity, type, or if the item is currently on discount or not. Due not when the user scrolls down the product page the navbar moves out of view to allow the user more space to view items but not the filter bar, it will remain at the top for the user's convenince.
                </p>
            </div>

            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/products/IMA-Avacyn,_Angel_of_Hope-None" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Item Page
                </h1>
            </a>
            <div className="mb-8">

                <a href="https://ecommerce-cards-57ca7.firebaseapp.com/products/IMA-Avacyn,_Angel_of_Hope-None">
                    <img src={item} 
                        alt="CCNA certification" 
                        className="block mx-auto mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50 md:float-right md:w-84 md:h-48 md:ml-4" 
                    />
                </a>
                <p className="text-justify max-w-prose text-xl mx-auto">
                    For the item page I made the main image very large and directly below it you will notice other images that the user can sort through to view the item from diffrent angles to have more context if the user would like to purche said item. To the right of the main image is a full description of the item, additionally the Purchase button is in clear view to help encourage users to add the item to their cart.
                </p>
            </div>

            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/products/IMA-Avacyn,_Angel_of_Hope-None" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Cart
                </h1>
            </a>

            <div className="mb-8">
            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/loginpage" className="hover:text-textHover">
                    <img src={cart} 
                        alt="CCNA certification" 
                        className="block mx-auto md:float-left md:w-84 md:h-48 mr-4 mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                    />
                </a>
                <p className="text-justify max-w-prose text-xl mx-auto">
                    Obviously in the cart page you would expect to find all the items in your cart as well as a short description and the quantity you are trying to purchase. The obviouse out of the way, red price tags will continue to show here as well as the discount percent for the user's convenince. On the bottom of the cart the total number of items, money saved, and current cart price is displayed. I placed this section with a lighter color to help the users understand this section is seperate from the section above.
                </p>
            </div>

            <a href="https://ecommerce-cards-57ca7.firebaseapp.com/loginpage" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Login
                </h1>
            </a>
            <div className="mb-8">
                <a href="https://ecommerce-cards-57ca7.firebaseapp.com/loginpage">
                    <img src={login} 
                        alt="CCNA certification" 
                        className="block mx-auto mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50 md:float-right md:w-84 md:h-48 md:ml-4" 
                    />
                </a>
                <p className="text-justify max-w-prose text-xl mx-auto">
                    The login page is very simple and self explanitory. New and old users can modify certain account settings if needed using their email, create accounts, and login. Again the administrator can login to add items, remove items, set discounts to items, update and change the caracels in the landing page.
                </p>
            </div>

            <br />
            <br />
            <div className="mt-6 flex justify-center items-center">
                <Link to="https://ecommerce-cards-57ca7.firebaseapp.com/" className="bg-buttonColor text-white px-6 py-2 rounded hover:bg-buttonHover transition transform duration-300 hover:scale-105">
                    Visit MTG Ecommerce Site
                </Link>
            </div>
            <br />
            <br />
        </div>
    );
}

export default MTGEcommerce;

import React, {userState, useEffect} from 'react';
import ccna from '../../assets/images/Project/Roadmap/ccna.png';
import cysa from '../../assets/images/Project/Roadmap/CySA+.png';
import pentest from '../../assets/images/Project/Roadmap/Pentest+.png';
import server from '../../assets/images/Project/Roadmap/Server+.png';
import azfundamentals from '../../assets/images/Project/Roadmap/az-900.png';


const roadmapItems = [
    {
        image: cysa,
        alt: "CySA Certification",
        description: "Cyber Security Analyst: March 2024",
        alignment: "left"
    },
    {
        image: azfundamentals,
        alt: "Azure Fundamentals Certification",
        description: "Azure Fundamentals: May 2024",
        alignment: "right"
    },
    {
        image: server,
        alt: "Server Plus Certification",
        description: "Server Plus: July 2024",
        alignment: "left"
    },
    {
        image: pentest,
        alt: "Penetration Tester Certification",
        description: "Penetration Tester: September 2024",
        alignment: "right"
    },
    {
        image: ccna,
        alt: "Cisco Certified Network Administrator Certification",
        description: "Cisco Certified Network Administrator: September 2024",
        alignment: "left"
    },
    // Add more items as needed
    
];



const FutureRoadmap = () => {
    return (
        <div className="bg-pageBackground p-4 md:p-8 lg:p-16 rounded shadow-lg mx-auto max-w-5xl">
            <h1 className="text-4xl font-bold mb-8 text-center fade-in">The Path Forward: My Development Roadmap</h1>
            <br />
            {roadmapItems.map((item, index) => (
            <div key={index} className={`mb-8 flex ${item.alignment === 'left' ? 'justify-start' : 'justify-end'}`}>
                <div className="text-center">
                    <img src={item.image} 
                        alt={item.alt} 
                        className="mx-auto md:mx-0 w-80 h-auto mb-2 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150" 
                    />
                    <p>{item.description}</p>
                </div>
            </div>
            ))}
            <br />
            <br />
        </div>
    );
};

export default FutureRoadmap;

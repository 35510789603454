import React from 'react';
import home from '../../assets/images/Project/mtgcollection/home.png';
import login from '../../assets/images/Project/mtgcollection/login.png';
import stats from '../../assets/images/Project/mtgcollection/stats.png';
import top100 from '../../assets/images/Project/mtgcollection/top100.png';
import database from '../../assets/images/Project/mtgcollection/database.png';


const MTGCollection = () => {
    return (
        <div className="bg-pageBackground p-4 md:p-8 lg:p-16 rounded shadow-lg fade-in mx-auto max-w-5xl">
            <h1 className="text-4xl text-center font-bold mb-4">MTG Collection Website</h1>
            

                <h2 className="text-2xl md:text-3xl mt-6 mb-2 text-center">Introduction</h2>
                <p>
                    The MTG Collection project is an initiative to catalog and track my growing collection of Magic: The Gathering cards. This platform allows me to quickly and easily manage cards, keep track of their details, and even see their current market value.
                </p>

                <h2 className="text-2xl md:text-3xl mt-6 mb-2 text-center">Technical Stack</h2>
                <ul>
                    <li><strong>Frontend:</strong> Built with JavaScript and React for a smooth and dynamic user experience.</li>
                    <li><strong>Backend:</strong> Leveraging Firebase for various solutions including:
                        <ul className="list-disc pl-5">
                            <li><strong>Firestore Database:</strong> This NoSQL database is where all card data is stored. Each card has attributes like name, type, color, edition, and more.</li>
                            <li><strong>Firebase Hosting:</strong> The entire platform is hosted seamlessly on Firebase, providing a fast and secure experience for users.</li>
                            <li><strong>Firebase Authentication:</strong> Enables secure access to the collection, ensuring that only authorized individuals can make updates.</li>
                        </ul>
                    </li>
                </ul>

                <h2 className="text-2xl md:text-3xl mt-6 mb-2 text-center">Data Structure Example</h2>
                <p>The following is an example of how I structered my main part of the firebase database(where my main card colleciton is stored). Keep in mind due to the read and write cost of firebase I developed other strategies to help keep my top100 and statistics pages not only uptodate but remain inexpinsive. </p>
                <pre className="bg-codeBackground text-white p-4 rounded overflow-x-auto text-sm md:text-base lg:text-lg">
                    {`{
"cards": {
    "cardId1": {
        "card_name": "Arbor Elf",
        "card_type": "Legendary Creature — Human Gamer",
        "cmc":"1"
        "color":"G"
        "edition_code":"A25"
        "edition_name":"Masters 25"
        "foil":"X"
        "is_multicolor":"not"
        "quantity":"3"
        "rarity":"common"
        "value": 0.16
    },
    "cardId2": {
        ...
    },
    ...
    }
}`}
                </pre>
            <a href="https://card-collection-1c1cb.firebaseapp.com/" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Landing Page
                </h1>
            </a>
            <div className="mb-8">
                <a href="https://card-collection-1c1cb.firebaseapp.com/">
                    <img src={home} 
                        alt="home page" 
                        className="block mx-auto md:float-left md:w-84 md:h-48 mr-4 mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                    />
                </a>

                <p className="text-justify max-w-prose mx-auto text-xl">
                    For the langing page I chose to focus on my colleciton as a whole. As you may see from the picture you can search my entire collection manully by scrolling or look up a card by name.This is the heart of the website which allows me or others to verify whether i do indeed have a specific card in my collection.
                </p>
            </div>
            <a href="https://card-collection-1c1cb.firebaseapp.com/statistics" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Statistics
                </h1>
            </a>

            <div className="mb-8">
                <a href="https://card-collection-1c1cb.firebaseapp.com/statistics">
                    <img src={stats} 
                        alt="Statistics Page" 
                        className="block mx-auto mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50 md:float-right md:w-84 md:h-48 md:ml-4" 
                    />
                </a>

                <p className="text-justify max-w-prose text-xl mx-auto">
                    The statistics page is my most proud page in all of webdevelopment I have created so far! It shows the complete break down of the collection as a whole in regards to price, quantity, rarity, foils cmc(converted mana cost) and others factors as well. Although the top of the page is filled with many charts representing values those who are not to interested in charts can scroll to the middle or bottom of the page to visually view the statistical breakout. I have made bar charts, pie charts and a top 5 cards by price for not only each color but also multicolor(any color that has more than one color, 2-5). Hopefully you will view my statistics page!
                </p>
            </div>
            
            <a href="https://card-collection-1c1cb.firebaseapp.com/top100" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Top 100
                </h1>
            </a>
            <div className="mb-8">
                <a href="https://card-collection-1c1cb.firebaseapp.com/top100">
                    <img src={top100} 
                        alt="CCNA certification" 
                        className="block mx-auto md:float-left md:w-84 md:h-48 mr-4 mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                    />
                </a>

                <p className="text-justify max-w-prose text-xl mx-auto">
                    The top100 page is the most common page to visit just due to the ease of understanding and intrigue. The page is defined by price eg. displays the top 100 most expensive cards in my collection currently. Although prices my differ from time to time I can log in at any time to update the price of each card in my collection(dialy price update is to taxing for the api I use, so update price when needed usually weekly). The page will start from 1 and go through down to 100, it displays the name, edition, quantity and value of the card. Also it displays the card image as well so you may get a better understanding of the card. This page is implemented with lazy loading to help reduce the burden of loading all the images from the api I request from.
                </p>
            </div>

            <a href="https://card-collection-1c1cb.firebaseapp.com/login" className="hover:text-textHover">
                <h1 className="text-2xl md:text-3xl mt-6 mb-2 text-center">
                    Login Page
                </h1>
                </a>

            <div className="mb-8">
                <a href="https://card-collection-1c1cb.firebaseapp.com/login">
                    <img src={login} 
                        alt="CCNA certification" 
                        className="block mx-auto mb-4 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-150 hover:z-50 md:float-right md:w-84 md:h-48 md:ml-4" 
                    />
                </a>

                <p className="text-justify max-w-prose text-xl mx-auto">
                    A simple login page allows the one authenticated user(me) to see another tab in the navbar called manage. Although maliciouse users are able to navigate to this page there are several strategies in place to keep my data from being modified. First is I have a complete .csv file as a safe backup and am able to completely reset the database. Second they must login through the login page. Third they must gain authority over the project to then be able to modify the database. The best protection is not perfect but a layered defense is the best defence.
                </p>
            </div>

            <div className="mt-8">
                <p className=" text-center max-w-prose text-xl mx-auto mb-4">Interested in exploring my collection?</p>
                <div className="flex justify-center items-center">
                    <a href="https://card-collection-1c1cb.firebaseapp.com/" className="bg-buttonColor text-white px-4 py-2 rounded shadow hover:bg-buttonHover transition transform duration-300 hover:scale-105">
                        MTG Collection
                    </a>
                </div>
            </div>
            <br />
            <br />
            <br />
        </div>
    );
}

export default MTGCollection;

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from "./components/Navbar/Navbar"
import Home from "./pages/Home/Home"
import Certifications from './pages/Certification/Certifications';
import MTGCollection from './pages/Project/MTGCollection';
import MTGEcommerce from './pages/Project/MTGEcommerce';
import MTGEcommerceUI from './pages/UIUXProject/MTGEcommerceUI';
import FoodTruck from './pages/UIUXProject/FoodTruck';
import MTGCollectionUI from './pages/UIUXProject/MTGCollectionUI';
import FutureRoadmap from './pages/Project/FutureRoadmap'
import './App.css'
import Contact from './pages/Contact/Contact'
import SimpleSites from './pages/Project/SimpleSites';
import Networks from './pages/Project/Networks';

const Layout = () => {
  return (
    <>
      <Navbar />
    </>
  )
}


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/certifications" element={<Certifications />} />
        <Route path="/mtg-collection" element={<MTGCollection />} />
        <Route path="/mtg-ecommerce" element={<MTGEcommerce />} />
        <Route path="/simple-sites" element={<SimpleSites />} />
        <Route path="/networks" element={<Networks />} />
        <Route path="/networks" element={<Networks />} />
        <Route path="/mtg-ecommerce-ui" element={<MTGEcommerceUI />} />
        <Route path="/food-truck" element={<FoodTruck />} />
        <Route path="/mtg-collection-ui" element={<MTGCollectionUI />} />
        <Route path="/future-roadmap" element={<FutureRoadmap />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;

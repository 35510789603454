import React from 'react';
import wireframeMealCustomization from "../../assets/images/UIUXProjects/FoodTruck/MealCustomizationPage.png"
import wireframeVendorPage from "../../assets/images/UIUXProjects/FoodTruck/VendorPage.png"
import wireframeLandingPage from "../../assets/images/UIUXProjects/FoodTruck/WireframeLandingPage.png"
import foodtruckHifiOne from "../../assets/images/UIUXProjects/FoodTruck/foodtruck-hifi-1.png"
import foodtruckHifiTwo from "../../assets/images/UIUXProjects/FoodTruck/foodtruck-hifi-2.png"
import foodtruckHifiThree from "../../assets/images/UIUXProjects/FoodTruck/foodtruck-hifi-3.png"


const BussinFoodProject = () => {
    return (
        <div className="bg-pageBackground p-8 md:p-16 rounded shadow-lg max-w-5xl mx-auto fade-in">
            <h2 className="text-3xl font-bold mb-8 fade-in text-center">Bussin Food</h2>

            {/* 2. Designer's Role */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Role & Contribution</h3>
                <p className="text-sm md:text-base">As the Lead UI/UX Designer for "Bussin Food," I single-handedly conceptualized and executed both the user interface (UI) and user experience (UX) designs from inception to completion, ensuring each element aligns seamlessly with our target audience's preferences and expectations.</p>
            </section>

            {/* 3. Project Goal */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Project Objectives</h3>
                <p className="text-sm md:text-base">
                    "Bussin Food" was conceived with a vision to serve as a bridge between food truck enthusiasts and vendors. For the users, the app provides a platform to effortlessly discover and order from food trucks in their vicinity, with real-time access to menus, ratings, and the distance of each truck. Meanwhile, for the food truck owners, it offers a golden opportunity for free advertising, minimizes the hassle of manual order-taking, and expands their reach to a broader audience, optimizing both customer experience and business growth.
                </p>
            </section>

            {/* 4. Target Audience */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Target Audience</h3>
                <p className="text-sm md:text-base">
                    Our primary audience for "Bussin Food" spans a diverse range of food enthusiasts and professionals:
                    <ul className="list-disc ml-4 mt-1 md:ml-5 md:mt-2">
                        <li><strong>DoorDash Users:</strong> Those accustomed to the convenience of ordering food at their fingertips.</li>
                        <li><strong>Party Goers:</strong> Individuals who frequent events and are often on the lookout for nearby food trucks to satiate their appetites.</li>
                        <li><strong>Food Truck Business Owners:</strong> Entrepreneurs seeking a platform for heightened visibility and streamlined order processing.</li>
                        <li><strong>Avid Food Tryers:</strong> Culinary adventurers eager to explore diverse food truck offerings in their vicinity.</li>
                    </ul>
                </p>
            </section>

            {/* 5. Key Challenges or Constraints */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">Key Challenges</h3>
                <p className="text-sm md:text-base">
                    In the journey to create a seamless and engaging app for both food truck enthusiasts and business owners, we identified several challenges to navigate:
                    <ul className="list-disc ml-4 mt-1 md:ml-5 md:mt-2">
                        <li><strong>Real-time Navigation:</strong> Ensuring precise distance calculation and accurate representation of food truck locations in real-time.</li>
                        <li><strong>Onboarding Food Truck Businesses:</strong> Persuading a traditionally mobile and offline business model to transition and trust a digital platform for operations and visibility.</li>
                        <li><strong>Authentic Review Integration:</strong> Crafting a robust and transparent system where users can share their genuine experiences, and businesses can constructively engage with the feedback.</li>
                        <li><strong>Real-time Menu Updates:</strong> Providing tools for food truck owners to swiftly update their offerings, ensuring customers always have the latest information.</li>
                    </ul>
                </p>
            </section>

            {/* 6. Research Study Details */}
            <section className="mb-4 md:mb-6">
                <h3 className="text-xl md:text-2xl mb-1 md:mb-2">User Research</h3>
                <p className="text-sm md:text-base">
                    Comprehensive user research was fundamental in shaping the direction and features of the Bussin Food app. Our approach encompassed:
                </p>
                <ul className="list-disc ml-4 mt-1 md:ml-5 md:mt-2">
                    <li><strong>Interviews:</strong> Engaged in detailed conversations with both potential users and food truck owners to understand their pain points, expectations, and aspirations for a platform like this.</li>
                    <li><strong>Empathy Maps:</strong> Used to visualize and categorize the varied responses, ensuring every stakeholder's voice was acknowledged and represented in the design process.</li>
                                <li><strong>Key Insights:</strong> The research underscored the dual benefits of the platform. While users craved a tool to discover and connect with local food trucks effortlessly, the food truck owners saw immense value in the platform as a self-advertising medium and a way to broaden their customer reach.</li>
                            </ul>
                        </section>

                        {/* User research pain points section */}
                        <section>
                            <div className="bg-gray-100 md:p-4 max-w-full mx-auto">
                                <h3 className="text-2xl mb-4 text-center">User Pain Points</h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow">
                                        <span className="text-3xl font-bold">1</span>
                                        <span className="font-semibold">Time Management</span>
                                        <p>Users struggle to track their favorite food trucks in real-time, while food truck operators face challenges managing orders and payments during peak hours due to staffing constraints.</p>
                                    </div>

                                    <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow">
                                        <span className="text-3xl font-bold">2</span>
                                        <span className="font-semibold">Accessibility</span>
                                        <p>Lack of a dedicated platform to provide real-time updates for mobile food businesses, creating a gap in accessibility for both owners and consumers.</p>
                                    </div>

                                    <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow">
                                        <span className="text-3xl font-bold">3</span>
                                        <span className="font-semibold">Real-time Tracking</span>
                                        <p>Users often remain unaware of the current location of their preferred food trucks, and truck operators miss out on a platform to advertise and broadcast their dynamic locations.</p>
                                    </div>

                                    <div className="flex flex-col items-center space-y-2 mb-6 md:hover:shadow-lg md:transition-shadow">
                                        <span className="text-3xl font-bold">4</span>
                                        <span className="font-semibold">Quality Assurance</span>
                                        <p>Customers often face uncertainty regarding the quality of food and service from untried food trucks, emphasizing the need for a reliable review and feedback system.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
            {/* End of user research pain points section */}


            {/* 7. Initial Design Concepts */}
            <section className="mb-6">
                <h3 className="text-2xl mb-2">Initial Design Vision</h3>
                <p>
                    Our inaugural design philosophy revolved around crafting a seamless experience for users. Central to this was an interactive map interface, ensuring real-time location tracking of food trucks. Additionally, the provision of a comprehensive list-view menu allowed users to effortlessly browse through offerings, and a dedicated user dashboard provided a personalized touch, streamlining order history, favorites, and more. Throughout the conceptualization, the paramount goal remained clear: an intuitive and user-centric design.
                </p>
            </section>

            <section className="mb-6">
            <h3 className="text-2xl mb-2 text-center">Wireframes</h3>
            <p className="mt-2 text-center">Bussin Food Mobile Interface Sketches</p>
                        
            <div className="flex flex-wrap justify-center md:justify-between space-y-4 md:space-y-0 md:space-x-4"> 
                <div className="relative group w-full md:w-auto">
                    <img 
                        className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                        src={wireframeLandingPage}
                        alt="Landing page wireframe" 
                    />
                    <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                        Landing page showcases user location and proximity to nearby food trucks. Featured meals, accentuated with ratings, are also suggested at the bottom, providing curated experiences.
                    </div>
                </div>
                
                <div className="relative group w-full md:w-auto">
                    <img 
                        className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                        src={wireframeVendorPage}
                        alt="Vendor menu page wireframe" 
                    />
                    <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                        Vendor Menu page: A standardized menu layout across all vendors ensures a unified, non-confusing experience, facilitating seamless order processes for users.
                    </div>
                </div>
                
                <div className="relative group w-full md:w-auto">
                    <img 
                        className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                        src={wireframeMealCustomization}
                        alt="Meal customization page wireframe" 
                    />
                    <div className="absolute top-0 left-0 bg-white text-black px-2 py-1 opacity-0 group-hover:opacity-100">
                        Meal Customization page: Detailed visual representation of each meal, enabling users to modify ingredients or items, ensuring their culinary experience is tailored to preference.
                    </div>
                </div>
            </div> 
        </section>


            {/* 9. User Testing Results */}
            <section className="mb-6">
                <h3 className="text-2xl mb-2">User Testing Results</h3>
                <p>
                    Comprehensive user testing was undertaken over two rigorous rounds, involving both customers and food truck owners as participants. Their invaluable feedback was instrumental in evolving our design from initial wireframes to a high-fidelity prototype, ensuring that Bussin Food resonates with its primary stakeholders.
                </p>
            </section>


            {/* 10. Mockups or High-fidelity Prototypes */}
            <section className="mb-6">
                <h3 className="text-2xl mb-2 text-center">Refined Desktop High-fidelity Prototypes</h3>
                <p className="mt-2 text-center">Showcasing detailed and interactive visual representations of the final app's user interface.</p>
                
                <div className="flex flex-wrap justify-center md:justify-between space-y-4 md:space-y-0 md:space-x-4"> 

                    <div className="relative group w-full md:w-auto">
                        <img 
                            className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                            src={foodtruckHifiThree}
                            alt="High-fidelity prototype of the Top 100 page" 
                        />
                    </div>

                    <div className="relative group w-full md:w-auto">
                        <img 
                            className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                            src={foodtruckHifiTwo}
                            alt="High-fidelity prototype of the Landing page" 
                        />
                    </div>

                    <div className="relative group w-full md:w-auto">
                        <img 
                            className="border-2 border-black w-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:opacity-90 hover:shadow-2xl" 
                            src={foodtruckHifiOne}
                            alt="High-fidelity prototype of the Statistics page" 
                        />
                    </div>
                </div> 
            </section>

            {/* 11. Call-to-Action */}
            <section className="mb-6">
                <h3 className="text-2xl mb-2 text-center">Visit The High-Fidelity Example in Figma</h3>
                <div className="flex justify-center items-center">
                    <a 
                    href="https://www.figma.com/file/YczzpiwfhmRKu6TDNEnAhK/Food-Truck?type=design&node-id=39-37&mode=design&t=C2NBNejz0VHz8N7D-0" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className=" bg-buttonColor hover:bg-buttonHover text-white py-3 px-6 rounded inline-block mt-4 shadow-lg transition transform duration-300 hover:scale-105"
                    >
                        View on Figma or Live Project
                    </a>
                </div>
            </section>
        </div>
    );
}

export default BussinFoodProject;

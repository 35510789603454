import React from 'react';
import { Link } from 'react-router-dom';
import accountImage from "../../assets/images/Home/account.png"


const Home = () => {
    return (
        <div className="bg-pageBackground p-8 lg:p-16 rounded shadow-lg mx-auto max-w-5xl">
            <section className="text-center mb-20">
                <h1 className="text-4xl font-bold mb-4 fade-in">Welcome to My Portfolio</h1>
                <p className="text-xl fade-in">
                    Greetings! I'm <span className="font-bold">Timothy Porter</span>, 
                    an adaptable and dedicated IT professional. With a robust foundation in 
                    both software development and IT infrastructure, I bring a comprehensive 
                    skill set to the table. My journey in the IT domain is marked by continuous 
                    learning and growth, underscored by key certifications such as CompTIA A+, 
                    Network+, and Security+. Whether it's developing user-centric web applications, 
                    securing networks, or providing top-tier IT support, my goal is to leverage 
                    my diverse skill set to drive technological solutions and innovations across 
                    multiple facets of the IT industry.
                </p>

            </section>


            <section className="mb-20">
                <img 
                    src={accountImage}
                    alt="Timothy Porter" 
                    className="float-left mr-10 w-1/3 rounded-lg transform transition-transform duration-500 hover:scale-150 hover:z-50"
                />
                <h2 className="text-2xl md:text-3xl mt-6 mb-2 fade-in">About Me</h2>
                <p className="text-xl fade-in">
                    I am a Designer with an insatiable curiosity about the mechanics 
                    of web applications. My journey in the tech world was fueled by an 
                    eagerness to understand how websites work, the intricacies of 
                    databases, the nuances of hosting, and the complexities of 
                    networking. While aesthetics matter, my primary drive is the 
                    functional underpinnings of digital platforms.
                </p>
                <p className="text-xl fade-in">
                    Diving deep into databases, I've explored how data can be 
                    efficiently stored, maintained, and retrieved, ensuring seamless 
                    user experiences. Hosting and networking aren't just terms for me; 
                    they are realms I've delved into, understanding how they influence 
                    site performance and accessibility.
                </p>
                <p className="text-xl fade-in">
                    My Magic The Gathering collection isn't just a passion but a 
                    testament to my technical skills. I've built 
                    a <Link to="/mtg-collection" className="font-semibold">Magic The 
                    Gathering Collection Website</Link> from scratch and an ecommerce 
                    platform, <Link className="font-semibold" to="mtg-ecommerce">Magic 
                    the Gathering ecommerce store</Link>, each showcasing my expertise 
                    in database management and website functionality.
                </p>
                <p className="text-xl fade-in">
                    While I design and develop, I ensure that security isn't 
                    compromised. My dedication to creating secure digital platforms is 
                    backed by 
                    my <Link className="font-semibold" to="/certifications#cybersecurity">
                        cybersecurity certifications</Link>. 
                        This ensures that my projects, while functionally robust, 
                        are also protected against potential digital threats.
                </p>
                <div className="clear-both"></div>
            </section>




            <section className="mb-20">
                <h2 className="text-2xl md:text-3xl mt-6 mb-2 text-center fade-in">Skills</h2>
                
                <div className="skills-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 fade-in">
                    {/* Programming Languages */}
                    <div>
                    <h3 className="font-bold text-xl mb-2">Programming Languages</h3>
                    <ul>
                        <li>JavaScript - Advanced</li>
                        <li>Python - Advanced</li>
                        <li>HTML/CSS - Intermediate</li>
                        <li>SQL - Intermediate</li>
                    </ul>
                    </div>

                    {/* Database Management */}
                    <div>
                    <h3 className="font-bold text-xl mb-2">Database Management</h3>
                    <ul>
                        <li>SQL - Advanced</li>
                        <li>MongoDB - Intermediate</li>
                        <li>Firebase (Firestore, Storage) - Intermediate</li>
                    </ul>
                    </div>

                    {/* Frameworks & Libraries */}
                    <div>
                    <h3 className="font-bold text-xl mb-2">Frameworks & Libraries</h3>
                    <ul>
                        <li>React - Advanced</li>
                        <li>Django - Intermediate</li>
                        <li>Flask - Intermediate</li>
                        <li>Figma - Intermediate</li>
                    </ul>
                    </div>

                    {/* Design & Other Skills */}
                    <div>
                    <h3 className="font-bold text-xl mb-2">Design & Other Skills</h3>
                    <ul>
                        <li>UI/UX Design - Intermediate</li>
                        <li>CyberSecurity - Intermediate</li>
                        <li>Networking - Intermediate</li>
                    </ul>
                    </div>

                    {/* Certifications */}
                    <div>
                    <h3 className="font-bold text-xl mb-2">Certifications</h3>
                    <ul>
                        <li>A+ Certified</li>
                        <li>Network+ Certified</li>
                        <li>Security+ Certified</li>
                    </ul>
                    </div>

                    {/* Certifications */}
                    <div>
                    <h3 className="font-bold text-xl mb-2">Supplemental Courses</h3>
                    <ul>
                        <li>Data Analytics - Google</li>
                        <li>UX Design - Google</li>
                        <li>Python - Michigan University</li>
                    </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;
import React from 'react';

const Contact = () => {
        return (
            <div className="max-w-md mx-auto mt-10 bg-pageBackground p-8 border border-gray-300 fade-in rounded-lg shadow-md">
                <h2 className="text-xl font-semibold text-gray-700">Contact Me</h2>
                <br />
                <div className="font-medium text-lg mb-2">Timothy Porter</div>
                <div className="text-gray-600 mb-1">(214) 909-4889</div>
                <div className="text-gray-600">T.PorterOfficial@gmail.com</div>
            </div>
        );
        
}

export default Contact;
